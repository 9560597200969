import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import styled from "styled-components";

const OuterPadding = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`;

export const WysiwygTemplate = ({ html }) => {
  return (
    <Layout>
      <OuterPadding>
        <HTMLContent content={html} />
      </OuterPadding>
    </Layout>
  );
};

export const WYSIWYG = (data) => {
  const {
    data: {
      markdownRemark: { html },
    },
  } = data;
  return <WysiwygTemplate html={html} />;
};

export const pageQuery = graphql`
  query WYSIWYGQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;

export default WYSIWYG;
